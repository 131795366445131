import { ref } from '@vue/composition-api'
import store from '@/store'
import transactionStoreModule from './transactionStoreModule'
if (!store.hasModule('transaction')) store.registerModule('transaction', transactionStoreModule)
import { Dialog } from '@core/utils/other'
import { i18n } from '@core/utils/filter'
import $global from '@/globalVariables'

export default function useTransaction() {
  const loading = ref(false)
  const emptyTransaction = ()=>({
    currency: $global.default_currency,
    date: (new Date).toLocaleDateString(undefined,{year: "numeric", month: "2-digit", day: "2-digit"}),
    tags:[]
  })

  const transactionData =  ref(emptyTransaction())
  const resetTransaction = () => transactionData.value = emptyTransaction()
 
  // 获取 Transaction 数据
  const fetchTransaction = (id, callback) => {
    if (!id) return
    return store.dispatch('supplier/fetchTransaction', id).then(response => {
      transactionData.value = response.supplier
      if (callback) callback(response)
    }).catch(() => resetTransaction() )
  }

  // 更新 Transaction 数据  
  const deleteTransaction = async id =>{
    const v = await Dialog(true, 'danger').fire({
      icon: 'warning',
      title: i18n('message.if_confirm_delete'),
      confirmButtonText: i18n('action.delete')
    })
    if (v.value) return store.dispatch('supplier/deleteTransaction', id)
  }


  return {
    loading,
    transactionData,
    resetTransaction,
    fetchTransaction,
    deleteTransaction,
  }
}
