<template>
  <component :is="isDialog?'q-dialog':'div'" id="transaction-list" v-model="isDialogOpen" @hide="cancel">
    <div :class="{'dialog-view':isDialog}">
      <ListTable ref="tableRef" slim tabGroup="finance" :tabOptions="tabOptions" tabTitleColor :columns="columns" :fetchAsync="fetchTransactions" dataName="transactions" :selectable="selectable" selectMulti search="amount,tags">
        <!-- Add button -->
        <template #add>
          <q-btn v-if="$can('create','Finance')" push no-caps color="primary" @click.stop="transactionEditRef.open().then(v=>v?tableRef.refetchTable():'')">
            <span class="text-nowrap text-capitalize">{{$t('action.add')}}</span>
          </q-btn>
        </template>
        <!-- Date -->
        <template #cell(date)="{ value }">
          <div>{{value | date}}</div>
          <small class="mt-50">{{value | time}}</small>
        </template>
        <!-- Account -->
        <template #cell(accountData.names)="{ item }">
          <div v-if="item.accountData.code" class="text-left">
            <q-chip size="sm" class="text-capitalize text-nowrap" text-color="white" :color="$t(`finance.${item.accountData.class}.color`)">
              {{item.accountData.names | name}}
            </q-chip>
          </div>
        </template>
        <!-- Entry OrderNo -->
        <template #cell(entry.orderNo)="{ item }">
          <div v-if="item.entry" class="text-left">
            <q-chip v-if="item.entry.orderNo" size="sm" color="light-primary">{{item.entry.orderNo}}</q-chip>
            <small class="d-block">{{item.entry.description}}</small>
          </div>
        </template>
        <!-- Amount -->
        <template #cell(amount)="{ item }">
          <div class="text-nowrap text-left" :class="item.amount<0?'text-danger':'text-primary'">{{item.amount | price($t(`currency.${item.currency}.sign`))}}</div>
        </template>
        <!-- User -->
        <template #cell(createdBy)="{ item }">
          <div v-if="item.createdBy" class="d-flex gap-2 align-items-center">
            <Avatar :url="item.createdBy.files" defaultIcon="user"/>
            <div class="text-left">
              <div>{{item.createdBy.name}}</div>
              <q-chip v-if="isMe(item.createdBy._id)" size="sm" color="primary" text-color="white" class="m-0">{{$t('user.me')}}</q-chip>
            </div>
          </div>
        </template>
        <!-- Tags -->
        <template #cell(tags)="{item}">
          <div class="d-flex flex-wrap gap-1 align-items-center">
            <q-chip dense v-for="(tag, index) in item.tags" color="light-primary" :key="index" class="m-0">{{tag}}</q-chip>
            <q-btn flat dense padding="0.25rem 1rem" @click.stop="updateTags(item)"><fa-icon class="text-danger mx-n1" icon="pen"/></q-btn>
          </div>
        </template>
        <!-- Actions -->
        <template #cell(actions)="{ item }">
          <div class="text-nowrap d-flex gap-3 align-items-center justify-content-center">
            <q-btn v-if="$can('update','Finance') && !item.entry" flat @click.stop="transactionEditRef.open(item._id).then(v=>v?tableRef.refetchTable():'')"><feather-icon icon="EditIcon" class="text-primary cursor-pointer"/></q-btn>
          </div>
        </template>
      </ListTable>
      <TransactionEdit ref="transactionEditRef"/>
      <FieldDialog ref="fieldDialogRef" />
    </div>
  </component>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { date, time, price, mmdd, i18n, name, isMe } from '@core/utils/filter'
import { Dialog } from '@core/utils/other'
import store from '@/store'
import TransactionEdit from './TransactionEdit'
import ListTable from '@core/components/ListTable'
import Avatar from '@core/components/Avatar'
import FieldDialog from '@core/components/FieldDialog'

export default {
  name: 'transaction-list',
  components: {
    Avatar, ListTable, FieldDialog,
    TransactionEdit
  },
  props:{
    isDialog: Boolean,
    hideTabs: Boolean,
    showIcon: Boolean,
    selectable: {
      type:Boolean,
      default: false
    },
    filters: {
      type: Object,
      default: ()=>{}
    },
    sort: String
  },
  filters:{
    date, time, price, mmdd, name
  },
  setup() {
    // Refs
    const transactionEditRef = ref(null)

    // Table
    const tableRef = ref(null)
    const columns = ref([
      { key: 'date', label:i18n('form.date'), isDateRange: true },
      { key: 'accountData.names', label:i18n('finance.account'), sortable: true },
      { key: 'amount', isNumber: true, sortable: true},
      { key: 'entry.orderNo',  label:i18n('form.order_number'), sortable: true },
      { key: 'tags', label:i18n('form.tags'), sortable: true},
      { key: 'createdBy', label:i18n('form.created_by'), sortable: true, noFilter: true},
      { key: 'actions', label:i18n('form.actions'), noFilter: true},
    ])

    // Tabs
    const tabOptions = ref([
      {key: "all",       value: ''},
      {key: "pending",   value: {'account':{$exists: false}}},
      {key: "asset",     value: {'accountData.class': 'asset'}},
      {key: "liability", value: {'accountData.class': 'liability'}},
      {key: "equity",    value: {'accountData.class': 'equity'}},
      {key: "revenue",   value: {'accountData.class': 'revenue'}},
      {key: "expense",   value: {'accountData.class': 'expense'}}
    ])
    
    // Action
    const fetchTransactions = params => store.dispatch('transaction/fetchTransactions', params)

    // Dialog view
    const isDialogOpen = ref(false)
    
    let cancelAction = () => isDialogOpen.value = false
    const cancel = ()=>cancelAction()
    const selectOne = ()=>new Promise(res => {
      // 隐藏column
      columns.value = columns.value.filter(c=>c.key!=='actions')
      isDialogOpen.value = true
      cancelAction = ()=>{
        isDialogOpen.value = false
        res(false)
      }
      watch(tableRef, ref=>ref?.clickRow().then(val=>{
        isDialogOpen.value = false
        res(val)
      }))
    })

    // Update tags
    const fieldDialogRef = ref(null)
    const updateTags = async obj =>{
      const newTags = await fieldDialogRef.value.openDialog({data:obj.tags, label:i18n('form.tags'), icon: ['fad','tags']})
      if (newTags === false) return
      store.dispatch('transaction/updateTags', {transactionId: obj._id, tags:newTags}).then(r=>{
        obj.tags = r.tags
        // fetchTransaction(obj._id, ()=>{
        //   isUpdated = true
        // })
      }).catch(e=> Dialog(false).fire({
        icon: 'error',
        title: e.message
      }))
    }

    return {
      isMe,
      // Dialog View
      isDialogOpen,
      cancel,
      selectOne,
      
      // Table
      columns,
      tableRef,

      // Action
      fetchTransactions,
      tabOptions,
      updateTags,

      // Refs
      transactionEditRef,
      fieldDialogRef
    }
  }
}
</script>
<style lang="scss">
#product-list {
  .dialog-view {
    max-width: 900px;
    width:100%;
  }
}
</style>