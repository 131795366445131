var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.isDialog?'q-dialog':'div',{tag:"component",attrs:{"id":"transaction-list"},on:{"hide":_vm.cancel},model:{value:(_vm.isDialogOpen),callback:function ($$v) {_vm.isDialogOpen=$$v},expression:"isDialogOpen"}},[_c('div',{class:{'dialog-view':_vm.isDialog}},[_c('ListTable',{ref:"tableRef",attrs:{"slim":"","tabGroup":"finance","tabOptions":_vm.tabOptions,"tabTitleColor":"","columns":_vm.columns,"fetchAsync":_vm.fetchTransactions,"dataName":"transactions","selectable":_vm.selectable,"selectMulti":"","search":"amount,tags"},scopedSlots:_vm._u([{key:"add",fn:function(){return [(_vm.$can('create','Finance'))?_c('q-btn',{attrs:{"push":"","no-caps":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.transactionEditRef.open().then(function (v){ return v?_vm.tableRef.refetchTable():''; })}}},[_c('span',{staticClass:"text-nowrap text-capitalize"},[_vm._v(_vm._s(_vm.$t('action.add')))])]):_vm._e()]},proxy:true},{key:"cell(date)",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm._f("date")(value)))]),_c('small',{staticClass:"mt-50"},[_vm._v(_vm._s(_vm._f("time")(value)))])]}},{key:"cell(accountData.names)",fn:function(ref){
var item = ref.item;
return [(item.accountData.code)?_c('div',{staticClass:"text-left"},[_c('q-chip',{staticClass:"text-capitalize text-nowrap",attrs:{"size":"sm","text-color":"white","color":_vm.$t(("finance." + (item.accountData.class) + ".color"))}},[_vm._v(" "+_vm._s(_vm._f("name")(item.accountData.names))+" ")])],1):_vm._e()]}},{key:"cell(entry.orderNo)",fn:function(ref){
var item = ref.item;
return [(item.entry)?_c('div',{staticClass:"text-left"},[(item.entry.orderNo)?_c('q-chip',{attrs:{"size":"sm","color":"light-primary"}},[_vm._v(_vm._s(item.entry.orderNo))]):_vm._e(),_c('small',{staticClass:"d-block"},[_vm._v(_vm._s(item.entry.description))])],1):_vm._e()]}},{key:"cell(amount)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-nowrap text-left",class:item.amount<0?'text-danger':'text-primary'},[_vm._v(_vm._s(_vm._f("price")(item.amount,_vm.$t(("currency." + (item.currency) + ".sign")))))])]}},{key:"cell(createdBy)",fn:function(ref){
var item = ref.item;
return [(item.createdBy)?_c('div',{staticClass:"d-flex gap-2 align-items-center"},[_c('Avatar',{attrs:{"url":item.createdBy.files,"defaultIcon":"user"}}),_c('div',{staticClass:"text-left"},[_c('div',[_vm._v(_vm._s(item.createdBy.name))]),(_vm.isMe(item.createdBy._id))?_c('q-chip',{staticClass:"m-0",attrs:{"size":"sm","color":"primary","text-color":"white"}},[_vm._v(_vm._s(_vm.$t('user.me')))]):_vm._e()],1)],1):_vm._e()]}},{key:"cell(tags)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-wrap gap-1 align-items-center"},[_vm._l((item.tags),function(tag,index){return _c('q-chip',{key:index,staticClass:"m-0",attrs:{"dense":"","color":"light-primary"}},[_vm._v(_vm._s(tag))])}),_c('q-btn',{attrs:{"flat":"","dense":"","padding":"0.25rem 1rem"},on:{"click":function($event){$event.stopPropagation();return _vm.updateTags(item)}}},[_c('fa-icon',{staticClass:"text-danger mx-n1",attrs:{"icon":"pen"}})],1)],2)]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-nowrap d-flex gap-3 align-items-center justify-content-center"},[(_vm.$can('update','Finance') && !item.entry)?_c('q-btn',{attrs:{"flat":""},on:{"click":function($event){$event.stopPropagation();_vm.transactionEditRef.open(item._id).then(function (v){ return v?_vm.tableRef.refetchTable():''; })}}},[_c('feather-icon',{staticClass:"text-primary cursor-pointer",attrs:{"icon":"EditIcon"}})],1):_vm._e()],1)]}}])}),_c('TransactionEdit',{ref:"transactionEditRef"}),_c('FieldDialog',{ref:"fieldDialogRef"})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }