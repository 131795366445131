<template>
  <component :is="isPage?'div':'q-dialog'" id="transaction-edit" v-model="isDialogOpen" @hidden="cancel" :persistent="persistent">
    <div class="card m-0 h-100 shadow-sm overflow-hidden" :class="{'dialog-view':!isPage}">
      <div v-if="!isPage" class="bg-light-secondary p-2 d-flex align-items-center flex-wrap gap-2 shadow-sm" style="z-index:10">
        <fa-icon v-if="" :icon="transactionData._id?'pen':'plus'" :class="transactionData._id?'text-primary':'text-success'"/>
        <h4 class="m-0">{{$t(`finance.transaction.${transactionData._id?'edit':'add'}`)}}</h4>
        <q-space />
        <q-btn flat padding="5px" color="primary" :to="{name: transactionData._id?'transaction-edit':'transaction-add', params:{transactionId: transactionData._id}}">
          <fa-icon icon="expand"/>
        </q-btn>
      </div>
      
      <q-form @submit="clickSubmit" class="h-100 d-flex flex-column">
        <component :is="isPage?'div':'q-scroll-area'" class="h-100" delay='600' 
          :thumb-style="{ right: '4px', borderRadius: '5px', backgroundColor: '#7367f0', width: '5px', opacity: 0.75 }" 
          :bar-style="{ right: '2px', borderRadius: '9px', backgroundColor: '#7367f0', width: '9px', opacity: 0.2 }">
          <div>
            <div class="p-2 flex gap-2 flex-column flex-sm-row">
              <!-- date -->
              <q-input outlined hide-bottom-space v-model="transactionData.date" mask="date" :rules="[val=>Boolean(val)||$t('rule.required'),'date']" class="mb-0 flex-even flex-size-4">
                <template #prepend><fa-icon :icon="['fad','calendar-minus']" fixed-width class="text-danger mr-1"/></template>
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                      <q-date v-model="transactionData.date" style="height:376px">
                        <div class="row items-center justify-end">
                          <q-btn v-close-popup label="Close" color="primary" flat />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
              <!-- account -->
              <q-select outlined hide-bottom-space v-model="transactionData.account" :options="accountOptions" :option-label="item=>name(item.names)" option-value="_id" emit-value map-options
                :loading="loading" :label="$t('finance.account')|capitalize" class="flex-even m-0 flex-size-4" :rules="[val=>Boolean(val)]" popup-content-class="mt-1">
                <template #prepend><fa-icon :icon="['far','sack-dollar']" fixed-width class="text-danger mr-25"/></template>
              </q-select>
              <!-- price -->
              <q-input type="number" outlined clearable hide-bottom-space :step="0.01" input-class="text-right" class="mb-0 flex-even flex-size-4"
                v-model.number="transactionData.price" :rules="[val=>val>=0 && val!==null]" :prefix="$t(`currency.${transactionData.currency}.sign`)" />
              <!-- tags -->
              <q-select v-model="transactionData.tags" outlined :label="$t('form.tags')|capitalize" new-value-mode="add-unique" :loading="loading" multiple hide-dropdown-icon use-input 
                popup-content-class="mt-1" :placeholder="$t('message.enter_add_new')" class="flex-even m-0 flex-size-6">
                <template #selected-item="scope">
                  <q-chip removable dense class="mr-50" @remove="scope.removeAtIndex(scope.index)" :tabindex="scope.tabindex" color="light-primary" text-color="primary">{{scope.opt}}</q-chip>
                </template>
                <template #prepend><fa-icon :icon="['fad','tags']" fixed-width class="text-primary mr-25"/></template>
              </q-select>
            </div>
            <!-- Image -->
            <h5 class="text-primary bg-light-secondary m-0 px-2 py-75 d-flex gap-2 align-items-center">
              <span class="flex gap-2 align-items-center text-capitalize">
                <fa-icon :icon="['fad','image']" fixed-width />
                {{$t('form.image')}}
              </span>
            </h5>
            <FileUpload size="160px" model="supplier" :modelId="transactionData._id" :files.sync="transactionData.files" limit="5" class="p-2"/>
          </div>
        </component>
        <div class="d-flex justify-content-between p-2 shadow-top bg-light-secondary" style="z-index:10">
          <q-btn push no-caps color="primary" class="mr-2" type="submit" :loading="loading" :label="capitalize($t('action.save'))"/>
          <q-btn push no-caps color="danger" :loading="loading" @click.prevent.stop="cancel" :label="capitalize($t('action.cancel'))"/>
        </div>
      </q-form>
      <q-inner-loading :showing="loading">
        <q-spinner-gears size="50px" color="primary" />
      </q-inner-loading>
    </div>
  </component>
</template>

<script>
import { ref } from '@vue/composition-api'
import { capitalize, i18n } from '@core/utils/filter'
import store from '@/store'
import { useRouter } from '@core/utils/utils'
import { Toast, Dialog } from '@core/utils/other'
import FileUpload from '@core/components/file-upload/FileUpload'
import useTransaction from './useTransaction'
export default {
  name: 'transaction-edit',
  components: {
    FileUpload
  },
  props:{
    isPage: Boolean,
    persistent: {
      type: Boolean,
      default: true
    },
  },
  filters:{
    capitalize
  },
  setup(props){
    const { route, router } = useRouter()
    const { 
      loading,
      transactionData,
      resetTransaction,
      fetchTransaction  
    } = useTransaction()

    const accountOptions = ref([])

    // Actions
    let submit = ()=>{
      loading.value = true
      store.dispatch(transactionData.value._id?'transaction/updateTransaction':'transaction/addTransaction', transactionData.value).then(() => {
        loading.value = false
        Toast.fire({icon: 'success', title: capitalize(i18n('form.success'))})
        router.go(-1)
      }).catch(e =>{
        loading.value = false
        Dialog(false).fire({ icon: 'error', title: e.message })
      })
    }
    const clickSubmit = () => submit()

    // Page view
    if (props.isPage) {
      resetTransaction()
      if (route.value.params.transactionId) {
        loading.value = true
        fetchTransaction(route.value.params.transactionId, ()=>{
          loading.value = false
        })
      }
    }
 
    // Dialog view
    const isDialogOpen = ref(false)
    let cancelAction = () => router.go(-1)
    const cancel = ()=>cancelAction()
    const open = async id => {
      resetTransaction()
      isDialogOpen.value = true
      if(id) {
        loading.value = true
        await fetchTransaction(id, ()=> loading.value = false)
      }
      return new Promise(res=>{
        submit = () => {    
          loading.value = true
          store.dispatch(id?'transaction/updateTransaction':'transaction/addTransaction', transactionData.value).then(() => {
            loading.value = false
            Toast.fire({icon: 'success', title: capitalize(i18n('form.success'))})
            isDialogOpen.value = false
            res(true)
          }).catch(e =>{
            loading.value = false
            Dialog(false).fire({ icon: 'error', title: e.message })
          })
        }
        cancelAction = ()=>{
          isDialogOpen.value = false
          res(false)
        }
      })
    }

    return {
      loading, capitalize,
      transactionData,

      accountOptions,

      // Dailog
      isDialogOpen,
      open,
      cancel,

      clickSubmit,
    }
  }
}
</script>

<style lang="scss">
#transaction-edit {
  .dialog-view {
    max-width: 800px;
    width:100%;
  }
}
</style>